import { useTranslation } from 'react-i18next';
import { Table, Button, Form, Input } from 'antd';
import axios from 'axios';
import { API_BASE_URL } from '../config';
import { useState, useEffect } from 'react';

function ViewPriceList() {
  const { t } = useTranslation();
  const token = localStorage.getItem('token');
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [total, setTotal] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });

  const columns = [
    {
      title: 'id',
      dataIndex: 'updatedId',
    },
    {
      title: 'Country Name',
      dataIndex: 'countryName',
    },
    {
      title: 'Country Code',
      dataIndex: 'countryCode',
    },
    {
      title: 'Price',
      dataIndex: 'pricing',
    },
  ];

  const getDataSource = async (payload = {}) => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `${API_BASE_URL}/octopus/api/admin/pricing/list`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const list = data?.data?.data;
      console.log(list);
      // setDataSource(Array.isArray(list) ? list : []);
      const retrievedData = Array.isArray(list) ? list : [];
      const updatedData = retrievedData.map((item, index) => ({
        ...item,
        updatedId: index + 1 + (payload.current - 1) * (payload.pageSize || 10),
        pricing: Number(item.pricing).toFixed(3),
      }));
      console.log('updatedData: ', updatedData);
      setDataSource(updatedData);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };
  const onTableChange = pagination => {
    setPagination(pagination);
    getDataSource(pagination);
  };

  useEffect(() => {
    getDataSource(pagination);
  }, [pagination]);

  return (
    <div style={{ overflowX: 'scroll', width: '1300px', height: '100%' }}>
      {console.log('data: ', dataSource)}
      <Table
        dataSource={dataSource}
        loading={loading}
        columns={columns}
        // pagination={{ total }}
        onChange={onTableChange}
      />
    </div>
  );
}

export default ViewPriceList;
