import { useTranslation } from 'react-i18next';
import { Button as ChakraButton, useToast } from '@chakra-ui/react';
import { Space, Table, Tag, Form, Input, Modal, Cascader, Select } from 'antd';
import axios from 'axios';
import { API_BASE_URL } from '../config';
import { useState, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

const CountryCodes = [{ "value": "86", "label": "中国" }, { "value": "852", "label": "中国香港" }, { "value": "853", "label": "中国澳门" }, { "value": "886", "label": "中国台湾" }, { "value": "1", "label": "美国" }, { "value": "1", "label": "加拿大" }, { "value": "55", "label": "巴西" }, { "value": "60", "label": "马来西亚" }, { "value": "61", "label": "澳洲" }, { "value": "81", "label": "日本" }, { "value": "82", "label": "韩国" }, { "value": "65", "label": "新加坡" }, { "value": "44", "label": "英国" }, { "value": "33", "label": "法国" }, { "value": "7", "label": "俄罗斯" }, { "value": "91", "label": "印度" }, { "value": "66", "label": "泰国" }, { "value": "49", "label": "德国" }, { "value": "39", "label": "意大利" }, { "value": "34", "label": "西班牙" }, { "value": "62", "label": "印尼" }, { "value": "855", "label": "柬埔寨" }, { "value": "95", "label": "缅甸" }, { "value": "673", "label": "文莱" }, { "value": "63", "label": "菲律宾" }, { "value": "84", "label": "越南" }, { "value": "856", "label": "老挝" }, { "value": "48", "label": "波兰" }];

function ViewQuota() {
	const toast = useToast();
	const [form] = Form.useForm();
	const { t } = useTranslation();
	const token = localStorage.getItem('token');
	const [loading, setLoading] = useState(false);
	const [isSubmiting, setIsSubmiting] = useState(false);
	const [open, setOpen] = useState(false);
	const [updateId, setUpdateId] = useState(false);
	const [dataSource, setDataSource] = useState([]);
	const [total, setTotal] = useState([]);

	const columns = [
		{
			title: 'id',
			dataIndex: 'id',
		},
		{
			title: 'roleType',
			dataIndex: 'roleType',
		},
		{
			title: 'account',
			dataIndex: 'account',
		},
		{
			title: 'createdAt',
			dataIndex: 'createdAt',
		},
		{
			title: 'Action',
			key: 'action',
			render: (_, record) => (
				<Space size="middle">
					<ChakraButton
						colorScheme="purple"
						type="submit"
						onClick={() => onEditButtonClick(record)}
					>
						Edit
					</ChakraButton>
				</Space>
			),
		},
	];

	const getDataSource = async () => {
		try {
			setLoading(true);
			const { data } = await axios.get(
				`${API_BASE_URL}/octopus/api/admin/user/list`,
				{
					headers: { Authorization: `Bearer ${token}` },
					params: { pageNo: 1, pageSize: 9999 },
				}
			);
			const list = data?.data?.list;
			setDataSource(Array.isArray(list) ? list : []);
			setTotal(data?.data?.total || 0);
		} catch (err) {
		} finally {
			setLoading(false);
		}
	};

	const onSubmitButtonClick = async values => {
		console.log('[onSubmitButtonClick]', values);
		const body = {
			...values,
			id: updateId || '',
		};
		try {
			setIsSubmiting(true);
			const { data } = await axios.post(
				`${API_BASE_URL}/octopus/api/admin/user/${updateId ? 'update' : 'create'
				}`,
				body,
				{ headers: { Authorization: `Bearer ${token}` } }
			);
			if (data?.code === 200) {
				toast({
					title: 'Success!',
					status: 'success',
					duration: 3000,
					isClosable: true,
				});
				setOpen(false);
				getDataSource();
				setTimeout(() => {
					form.resetFields();
				}, 500);
			} else {
				throw new Error(data?.message);
			}
		} catch (error) {
			toast({
				title: error?.message || 'System Error',
				description: 'Try again.',
				status: 'error',
				duration: 3000,
				isClosable: true,
			});
			console.log('[onSubmitButtonClick Erro]', error);
		} finally {
			setIsSubmiting(false);
		}
	};

	const onEditButtonClick = e => {
		setUpdateId(e.id);
		console.log('[onEditButtonClick]', e, form);
		form.setFieldsValue({
			account: e.account,
			password: '',
		});
		setOpen(true);
	};

	const onCreateButtonClick = () => {
		setUpdateId('');
		form.resetFields();
		setOpen(true);
	};

	useEffect(() => {
		getDataSource();
	}, []);

	return (
		<>
			<div
				style={{
					display: 'flex',
					justifyContent: 'flex-end',
				}}
			>
				<ChakraButton
					colorScheme="purple"
					type="submit"
					mt="24"
					mb="12"
					onClick={onCreateButtonClick}
				>
					Create Admin User
				</ChakraButton>
			</div>
			<Table
				dataSource={dataSource}
				loading={loading}
				columns={columns}
				pagination={{ total }}
			/>
			<Modal
				open={open}
				title="Create/Edit Admin Account"
				okText="Submit"
				cancelText="Cancel"
				confirmLoading={isSubmiting}
				onCancel={() => setOpen(false)}
				onOk={() => {
					form
						.validateFields()
						.then(values => {
							onSubmitButtonClick(values);
						})
						.catch(info => {
							console.log('Validate Failed:', info);
						});
				}}
			>
				<Form
					form={form}
					layout="vertical"
					name="form_in_modal"
					initialValues={{ modifier: 'public' }}
				>
					<Form.Item
						name="account"
						label="account"
						rules={[
							{
								required: true,
								message: 'Please input the account of collection!',
							},
						]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="password"
						label="password"
					>
						<Input />
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
}

export default ViewQuota;
