import { useState, useContext } from 'react';
import {
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  Box,
  Link,
  Avatar,
  FormControl,
  FormHelperText,
  FormErrorMessage,
  InputRightElement,
  useToast,
} from '@chakra-ui/react';
import { EmailIcon, LockIcon } from '@chakra-ui/icons';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL } from '../config';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../contexts/index.tsx';
import Navbar from '../navigation/Navbar';

const LoginContainer = props => {
  const { t } = useTranslation();
  const [mobileOpen, setMobileOpen] = useState(false);
  // const { userInfo } = useContext(AuthContext);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const toast = useToast();
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const [showPassword, setShowPassword] = useState(false);

  const handleShowClick = () => setShowPassword(!showPassword);

  const onLogin = async values => {
    try {
      const login = await axios.post(
        `${API_BASE_URL}/octopus/api/admin/user/login`,
        values
      );
      console.log('login data: ', login?.data);
      const token = login?.data?.data?.token;
      console.log('login token: ', token);
      if (token) {
        localStorage.setItem('token', token);
        navigate('/messages/send');
      } else {
        throw new Error(login?.data?.message || '');
      }
    } catch (error) {
      toast({
        title: error?.message || 'System Error.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Flex
        justifyContent="left"
        alignItems="end"
        paddingLeft="1380px"
        paddingTop="5px"
        backgroundColor="gray.200"
        position="relative"
      >
        <Navbar onDrawerToggle={handleDrawerToggle} />
      </Flex>
      <Flex
        flexDirection="column"
        width="100wh"
        height="100vh"
        backgroundColor="gray.200"
        justifyContent="center"
        alignItems="center"
      >
        <Stack
          flexDir="column"
          mb="2"
          justifyContent="center"
          alignItems="center"
        >
          <Avatar bg="purple.500" />
          <Heading color="purple.500">{t('welcome')}</Heading>
          <Box minW={{ base: '90%', md: '468px' }}>
            <form onSubmit={handleSubmit(onLogin)}>
              <Stack
                spacing={4}
                p="1rem"
                backgroundColor="whiteAlpha.900"
                boxShadow="md"
              >
                <FormControl isInvalid={errors['username']}>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<EmailIcon color="gray.300" />}
                    />
                    <Input
                      placeholder={t('username')}
                      {...register('username', { required: true })}
                    />
                  </InputGroup>
                  <FormErrorMessage>
                    {errors['username'] && 'Username is required'}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors['password']}>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      color="gray.300"
                      children={<LockIcon color="gray.300" />}
                    />
                    <Input
                      type={showPassword ? 'text' : 'password'}
                      placeholder={t('password')}
                      {...register('password', { required: true })}
                    />
                    <InputRightElement width="4.5rem">
                      <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                        {showPassword ? 'Hide' : t('show')}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                  <FormErrorMessage>
                    {errors['password'] && 'Password is required'}
                  </FormErrorMessage>
                  {/* <FormHelperText textAlign="right">
                  <Link>forgot password?</Link>
                </FormHelperText> */}
                </FormControl>
                <Button
                  borderRadius={0}
                  type="submit"
                  variant="solid"
                  colorScheme="purple"
                  width="full"
                >
                  {t('login')}
                </Button>
              </Stack>
            </form>
          </Box>
        </Stack>
        {/* <Box>
        New to us?{' '}
        <Link color="teal.500" href="#">
          Sign Up
        </Link>
      </Box> */}
      </Flex>
    </>
  );
};

export default LoginContainer;
