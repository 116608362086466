import { Box, Text, Image, Grid, AbsoluteCenter } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../config';
import phoneTemplate from '../../phone.png';
// import logo from './logo.svg';
export default function EmulatorOutline({ message }) {
  const style = {
    color: '#ff9c27b0',
  };
  const [showText, setShowText] = useState(true);
  var characterChunks = [];
  let c = 0;
  if (message !== null) {
    // for (let i = 0, charLength = message.length; i < charLength; i += 140) {
    //   // characterChunks.push(message.substring(i, i + 140));
    //   characterChunks.push({ data: message.substring(i, i + 140), key: c });
    //   c += 1;
    // }
    characterChunks = message.split('\n').map((line, index) => ({
      data: line,
      key: index,
    }));
    // console.log(characterChunks);
  }
  useEffect(() => {
    const interval = setInterval(() => {
      setShowText(showText => !showText);
    }, 1000);
  }, []);

  return (
    <>
      <Box
        minHeight="580px"
        bgImage={phoneTemplate}
        bgSize="cover"
        bgPosition="center"
        maxWidth="300px"
        py="6"
      >
        {' '}
        <Box overflowY="auto" maxHeight="480px" mt="6">
          {/* {characterChunks.map(obj => (
            <Box
              position="relative"
              backgroundColor="purple.500"
              minHeight="50px"
              borderRadius="20"
              textColor="white"
              // mx={{ lg: '12', xl: '20', base: '12' }}
              mx="12"
              // width={{ base: '65%', xl: '50%' }}
              mt="6"
              float="left"
              textAlign="left"
              pl="20px"
              pr="20px"
              py="10px"
              key={obj.key}
            >
              {obj.data}
            </Box>
          ))} */}
          {message && (
            <Box
              position="relative"
              backgroundColor="purple.500"
              minHeight="50px"
              borderRadius="20"
              textColor="white"
              mx="12"
              mt="6"
              float="left"
              textAlign="left"
              pl="20px"
              pr="20px"
              py="10px"
              whiteSpace="pre-wrap" // Preserve whitespace and line breaks
            >
              {message}
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}
