import { useTranslation } from 'react-i18next';
import { Button as ChakraButton, useToast } from '@chakra-ui/react';
import {
  Space,
  Table,
  Tag,
  Button,
  Form,
  Input,
  Modal,
  Cascader,
  Select,
} from 'antd';
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
import { API_BASE_URL } from '../config';
import { useState, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

const CountryCodes = [
  { value: 244, label: 'Angola', price: 0.0497 },
  { value: 54, label: 'Argentina', price: 0.0582 },
  { value: 374, label: 'Armenia', price: 0.1582 },
  { value: 61, label: 'Australia', price: 0.015 },
  { value: 994, label: 'Azerbaijan', price: 0.132 },
  { value: 973, label: 'Bahrain', price: 0.0276 },
  { value: 880, label: 'Bangladesh', price: 0.2166 },
  { value: 375, label: 'Belarus', price: 0.168 },
  { value: 32, label: 'Belgium', price: 0.0606 },
  { value: 975, label: 'Bhutan', price: 0.3 },
  { value: 55, label: 'Brazil', price: 0.0094 },
  { value: 673, label: 'Brunei', price: 0.0047 },
  { value: 855, label: 'Cambodia', price: 0.141 },
  { value: 1, label: 'Canada', price: 0.01 },
  { value: 56, label: 'Chile', price: 0.0059 },
  { value: 86, label: 'China', price: 0.068 },
  { value: 57, label: 'Colombia', price: 0.0114 },
  { value: 242, label: 'Congo', price: 0.0186 },
  { value: 53, label: 'Cuba', price: 0.0552 },
  { value: 45, label: 'Denmark', price: 0.0422 },
  { value: 20, label: 'Egypt', price: 0.1728 },
  { value: 251, label: 'Ethiopia', price: 0.0122 },
  { value: 358, label: 'Finland', price: 0.0516 },
  { value: 33, label: 'France', price: 0.0372 },
  { value: 995, label: 'Georgia', price: 0.1112 },
  { value: 49, label: 'Germany', price: 0.0732 },
  { value: 852, label: 'Hong Kong', price: 0.0463 },
  { value: 91, label: 'India', price: 0.0204 },
  { value: 62, label: 'Indonesia', price: 0.3372 },
  { value: 98, label: 'Iran', price: 0.1896 },
  { value: 972, label: 'Israel', price: 0.252 },
  { value: 39, label: 'Italy', price: 0.0312 },
  { value: 81, label: 'Japan', price: 0.0408 },
  { value: 7, label: 'Kazakhstan', price: 0.1854 },
  { value: 254, label: 'Kenya', price: 0.0072 },
  { value: 996, label: 'Kyrgyzstan', price: 0.2412 },
  { value: 856, label: 'Laos', price: 0.0371 },
  { value: 371, label: 'Latvia', price: 0.0362 },
  { value: 853, label: 'Macao', price: 0.0143 },
  { value: 60, label: 'Malaysia', price: 0.0756 },
  { value: 230, label: 'Mauritius', price: 0.0432 },
  { value: 52, label: 'Mexico', price: 0.0054 },
  { value: 373, label: 'Moldova', price: 0.0754 },
  { value: 976, label: 'Mongolia', price: 0.198 },
  { value: 95, label: 'Myanmar', price: 0.1199 },
  { value: 31, label: 'Netherlands', price: 0.078 },
  { value: 64, label: 'New Zealand', price: 0.0569 },
  { value: 234, label: 'Nigeria', price: 0.1284 },
  { value: 47, label: 'Norway', price: 0.1236 },
  { value: 968, label: 'Oman', price: 0.0866 },
  { value: 92, label: 'Pakistan', price: 0.33 },
  { value: 51, label: 'Peru', price: 0.1114 },
  { value: 63, label: 'Philippines', price: 0.0033 },
  { value: 48, label: 'Poland', price: 0.015 },
  { value: 351, label: 'Portugal', price: 0.0179 },
  { value: 7, label: 'Russia', price: 0.21 },
  { value: 966, label: 'Saudi Arabia', price: 0.1014 },
  { value: 65, label: 'Singapore', price: 0.0278 },
  { value: 27, label: 'South Africa', price: 0.0694 },
  { value: 34, label: 'Spain', price: 0.0288 },
  { value: 94, label: 'Sri Lanka', price: 0.336 },
  { value: 46, label: 'Sweden', price: 0.0516 },
  { value: 41, label: 'Switzerland', price: 0.1028 },
  { value: 886, label: 'Taiwan', price: 0.021 },
  { value: 992, label: 'Tajikistan', price: 0.2701 },
  { value: 66, label: 'Thailand', price: 0.0038 },
  { value: 90, label: 'Turkey', price: 0.0016 },
  { value: 971, label: 'United Arab Emirates', price: 0.0161 },
  { value: 44, label: 'United Kingdom', price: 0.0425 },
  { value: 1, label: 'United States', price: 0.0119 },
  { value: 998, label: 'Uzbekistan', price: 0.2472 },
  { value: 84, label: 'Viet Nam', price: 0.1052 },
];

function ViewQuota() {
  const toast = useToast();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const token = localStorage.getItem('token');
  const [loading, setLoading] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [open, setOpen] = useState(false);
  const [updateId, setUpdateId] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [total, setTotal] = useState([]);
  const [allowedCountryCodes, setAllowedCountryCodes] = useState([]);

  const columns = [
    {
      title: 'type',
      dataIndex: 'type',
    },
    {
      title: 'aliases',
      dataIndex: 'nickname',
    },
    {
      title: 'username',
      dataIndex: 'username',
    },
    {
      title: 'password',
      dataIndex: 'password',
    },
    {
      title: 'allowedCountryCodes',
      dataIndex: 'allowedCountryCodes',
    },
    {
      title: 'operator',
      dataIndex: 'operator',
    },
    {
      title: 'createdAt',
      dataIndex: 'createdAt',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <ChakraButton
            colorScheme="purple"
            type="submit"
            onClick={() => onEditButtonClick(record)}
          >
            Edit
          </ChakraButton>
        </Space>
      ),
    },
  ];

  const getDataSource = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `${API_BASE_URL}/octopus/api/admin/provider/list`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const list = data?.data?.list;
      setDataSource(Array.isArray(list) ? list : []);
      setTotal(data?.data?.total || 0);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };
  const handlePrice = async (selectedValue, index) => {
    // const selectedCountry = CountryCodes.find(
    //   country => country.value === selectedValue
    // );

    // Extract the price from the selected country object
    const { data } = await axios.post(
      `${API_BASE_URL}/octopus/api/admin/pricing/getPrice`,
      { countryCode: selectedValue },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    const price = parseFloat(data.data.pricing).toFixed(3);
    // const price = selectedCountry ? selectedCountry.price : null;

    const oldAllowedCountryCodes = form.getFieldValue('allowedCountryCodes');
    oldAllowedCountryCodes[index].price = price;

    form.setFieldValue(
      'allowedCountryCodes',
      JSON.parse(JSON.stringify(oldAllowedCountryCodes))
    );
  };

  const onSubmitButtonClick = async values => {
    console.log('[onSubmitButtonClick]', values);
    const { allowedCountryCodes, ...otherValues } = values;

    const body = {
      ...otherValues,
      allowedCountryCodes: JSON.stringify(allowedCountryCodes),
      id: updateId || '',
    };
    try {
      setIsSubmiting(true);
      const { data } = await axios.post(
        `${API_BASE_URL}/octopus/api/admin/provider/${
          updateId ? 'update' : 'create'
        }`,
        body,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (data?.code === 200) {
        toast({
          title: 'Success!',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        setOpen(false);
        getDataSource();
        setTimeout(() => {
          form.resetFields();
        }, 500);
      } else {
        throw new Error(data?.message);
      }
    } catch (error) {
      toast({
        title: error?.message || 'System Error',
        description: 'Try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      console.log('[onSubmitButtonClick Erro]', error);
    } finally {
      setIsSubmiting(false);
    }
  };

  const onEditButtonClick = e => {
    setUpdateId(e.id);
    console.log('[onEditButtonClick]', e, form);
    let allowedCountryCodes = [];
    try {
      allowedCountryCodes = JSON.parse(e.allowedCountryCodes || '');
      if (!Array.isArray(allowedCountryCodes)) {
        allowedCountryCodes = (`${e.allowedCountryCodes}` || '')
          .split(',')
          .map(code => ({ price: '', code, markup: '' }));
      }
    } catch (error) {
      allowedCountryCodes = (`${e.allowedCountryCodes}` || '')
        .split(',')
        .map(code => ({ price: '', code, markup: '' }));
    }
    allowedCountryCodes = allowedCountryCodes.map(item => ({
      ...item,
      price: item.price || '',
    }));
    setAllowedCountryCodes(allowedCountryCodes);
    form.setFieldsValue({
      username: e.username,
      nickname: e.nickname || '',
      password: e.password,
      type: e.type,
      operator: e.operator,
      allowedCountryCodes,
    });
    setOpen(true);
  };

  const onCreateButtonClick = () => {
    setUpdateId('');
    form.resetFields();
    setOpen(true);
    setAllowedCountryCodes([]);
  };

  const onAddAllowedCountryCode = e => {
    console.log('e', e);
    setAllowedCountryCodes([...allowedCountryCodes, { price: '', code: '' }]);
  };

  const onReduceAllowedCountryCode = index => {
    let newAllowedCountryCodes = allowedCountryCodes.slice(0);
    newAllowedCountryCodes.splice(index, 1);
    form.setFieldValue('allowedCountryCodes', newAllowedCountryCodes);
    setAllowedCountryCodes(newAllowedCountryCodes);
  };

  useEffect(() => {
    getDataSource();
  }, []);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <ChakraButton
          colorScheme="purple"
          type="submit"
          mt="24"
          mb="12"
          onClick={onCreateButtonClick}
        >
          Create Provider
        </ChakraButton>
      </div>
      <Table
        dataSource={dataSource}
        loading={loading}
        columns={columns}
        pagination={{ total }}
      />
      <Modal
        open={open}
        title="Create/Edit Provider"
        okText="Submit"
        cancelText="Cancel"
        width={800}
        confirmLoading={isSubmiting}
        onCancel={() => setOpen(false)}
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              onSubmitButtonClick(values);
            })
            .catch(info => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{ modifier: 'public' }}
        >
          <Form.Item
            name="type"
            label="type"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              placeholder={'Please select'}
              options={[
                { value: 'OTP', label: 'OTP' },
                { value: 'SCT', label: 'SCT' },
              ]}
            />
          </Form.Item>
          <Form.Item
            name="nickname"
            label="Aliases"
            rules={[
              {
                required: true,
                message: 'Please input the Aliases of collection!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="username"
            label="username"
            rules={[
              {
                required: true,
                message: 'Please input the username of collection!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="password"
            label="password"
            rules={[
              {
                required: true,
                message: 'Please input the password of collection!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          {allowedCountryCodes.map((item, index) => (
            <div style={{ display: 'flex', alignItems: 'center' }} key={index}>
              <Form.Item
                name={['allowedCountryCodes', index, 'code']}
                label="Allowed Countries"
                rules={[{ required: true }]}
              >
                <Select
                  placeholder={'Please select'}
                  options={CountryCodes}
                  onSelect={e => handlePrice(e, index)}
                />
              </Form.Item>
              <Form.Item
                name={['allowedCountryCodes', index, 'price']}
                label="Price"
                rules={[{ required: true }]}
                style={{ marginLeft: '10px' }}
              >
                <Input
                  placeholder="Place input markup ration"
                  type="number"
                  readOnly
                  addonAfter="%"
                />
              </Form.Item>
              <Form.Item
                name={['allowedCountryCodes', index, 'markup']}
                label="Markup"
                rules={[{ required: true }]}
                style={{ marginLeft: '10px' }}
              >
                <Input
                  placeholder="Place input markup ration"
                  type="number"
                  addonAfter="%"
                />
              </Form.Item>
              <Button
                style={{ margin: '4px 0 0 4px' }}
                type="primary"
                shape="circle"
                onClick={() => onReduceAllowedCountryCode(index)}
                icon={<DeleteOutlined />}
              />
              {index === allowedCountryCodes.length - 1 ? (
                <Button
                  style={{ margin: '4px 0 0 4px' }}
                  type="primary"
                  shape="circle"
                  onClick={() => onAddAllowedCountryCode(index)}
                  icon={<PlusCircleOutlined />}
                />
              ) : null}
            </div>
          ))}
          <Form.Item name="operator" label="operator">
            <Select
              placeholder={'Please select'}
              allowClear
              options={[
                { value: 'CMCC', label: 'CMCC/中国移动' },
                { value: 'CUCC', label: 'CUCC/中国联通' },
                { value: 'CTCC', label: 'CTCC/中国电信' },
                { value: 'ALL', label: '不限' },
              ]}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default ViewQuota;
