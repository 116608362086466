import { Button as ChakraButton, useToast } from '@chakra-ui/react';
import { Space, Table, Form, Input, Modal, Cascader, Select } from 'antd';
import axios from 'axios';
import { API_BASE_URL } from '../config';
import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../contexts/index.tsx';

function ViewQuota() {
  const { userInfo } = useContext(AuthContext);
  const toast = useToast();
  const [form] = Form.useForm();
  const token = localStorage.getItem('token');
  const [loading, setLoading] = useState(true);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [open, setOpen] = useState(false);
  const [updateId, setUpdateId] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [providerList, setProviderList] = useState([]);
  const [total, setTotal] = useState(0);

  const columns = [
    {
      title: 'name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Limit Balance',
      dataIndex: 'limitBalance',
      key: 'limitBalance',
    },
    {
      title: 'createdAt',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Providers',
      key: 'providers',
      render: (_, record) => record.providers.map(n => n.account).join('、'),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <ChakraButton
            colorScheme="purple"
            type="submit"
            onClick={() => onEditButtonClick(record)}
          >
            Edit
          </ChakraButton>
        </Space>
      ),
    },
  ].filter(n => n);

  const getDataSource = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `${API_BASE_URL}/octopus/api/admin/alerts/list`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const list = data?.data?.list;
      setDataSource(Array.isArray(list) ? list : []);
      setTotal(data?.data?.total || 0);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const getProviderList = async () => {
    try {
      const { data } = await axios.get(
        `${API_BASE_URL}/octopus/api/admin/provider/list`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const list = data?.data?.list;
      let options = (Array.isArray(list) ? list : []).reduce((p, v) => {
        const i = p.findIndex(n => n.value === v.type);
        const item = { value: v.id, label: v.username };
        if (i !== -1) {
          p[i].children.push(item);
        } else {
          p.push({ label: v.type, value: v.type, children: [item] });
        }
        return p;
      }, []);
      setProviderList(options);
    } catch (err) {
    } finally {
    }
  };

  const onSubmitButtonClick = async values => {
    console.log('[onSubmitButtonClick]', values);
    const providerIds = (values.providerIds || []).map(n => n[1]);
    const body = { ...values, providerIds, id: updateId || '' };
    let apiName = updateId ? 'update' : 'create';
    try {
      setIsSubmiting(true);
      const { data } = await axios.post(
        `${API_BASE_URL}/octopus/api/admin/alerts/${apiName}`,
        body,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (data?.code === 200) {
        toast({
          title: 'Success!',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        setOpen(false);
        getDataSource();
        setTimeout(() => {
          form.resetFields();
        }, 500);
      } else {
        throw new Error(data?.message);
      }
    } catch (error) {
      toast({
        title: error?.message || 'System Error',
        description: 'Try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      console.log('[onSubmitButtonClick Erro]', error);
    } finally {
      setIsSubmiting(false);
    }
  };

  const onEditButtonClick = e => {
    setUpdateId(e.id);
    console.log('[onEditButtonClick]', e, form);
    form.setFieldsValue({
      name: e.name,
      limitBalance: e.limitBalance,
      providerIds: (e.providers || []).map(item => [item.type, item.id]),
      email: e.email,
    });
    setOpen(true);
  };

  const onCreateButtonClick = () => {
    setUpdateId('');
    form.resetFields();
    setOpen(true);
  };

  useEffect(() => {
    if (userInfo.roleType) {
      getDataSource();
      getProviderList();
    }
  }, [userInfo]);

  return (
    <>
      <ChakraButton
        colorScheme="purple"
        type="submit"
        mb="12"
        onClick={onCreateButtonClick}
      >
        Create Alerts
      </ChakraButton>
      <Table
        dataSource={dataSource}
        columns={columns}
        loading={loading}
        pagination={{ total }}
      />
      <Modal
        open={open}
        title={updateId ? 'Edit Alerts' : 'Create Alerts'}
        okText="Submit"
        cancelText="Cancel"
        confirmLoading={isSubmiting}
        onCancel={() => setOpen(false)}
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              onSubmitButtonClick(values);
            })
            .catch(info => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{ modifier: 'public' }}
        >
          <Form.Item
            name="name"
            label="Alert Name"
            rules={[
              {
                required: true,
                message: 'Please input the name of collection!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message: 'Please input the email',
              },
              {
                type: 'email',
                message: 'Please enter a valid email address',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="limitBalance"
            label="Alert Limit Balance"
            rules={[
              {
                required: true,
                message: 'Please input the Alert Limit Balance of collection!',
              },
            ]}
          >
            <Input
              placeholder={'Please input'}
              addonAfter="$USD"
              type="number"
            />
          </Form.Item>
          <Form.Item
            name="providerIds"
            label="providerIds"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Cascader
              options={providerList}
              placeholder="Please select"
              multiple
              showCheckedStrategy={Cascader.SHOW_CHILD}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default ViewQuota;
