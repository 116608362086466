import { useEffect, useState, useContext } from 'react';
import {
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  Box,
  Link,
  Avatar,
  FormControl,
  FormHelperText,
  FormErrorMessage,
  InputRightElement,
  useToast,
  GridItem,
  Grid,
} from '@chakra-ui/react';
import { EmailIcon, LockIcon } from '@chakra-ui/icons';
import { Card } from 'antd';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import {
  API_BASE_URL,
  EXAPAY_URL,
  EXAPAY_API_KEY,
  EXAPAY_RECEIVER_EMAIL,
  EXAPAY_RECEIVER_NAME,
  EXAPAY_TOKEN,
} from '../config';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../contexts/index.tsx';
import Navbar from '../navigation/Navbar';
// import {
//   EXAPAY_BASE_URL,
//   EXAPAY_RECEIVER_EMAIL,
//   EXAPAY_RECEIVER_NAME,
//   EXAPAY_TOKEN,
// } from '../config';

const OrderConfirmation = props => {
  const { userInfo } = useContext(AuthContext);
  const params = useParams();
  const token = localStorage.getItem('token');

  const [values, setValues] = useState({
    amount: '',
    chainId: '',
    chainName: '',
    orderId: '',
  });

  const getUrlValues = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const amount = urlParams.get('amount');
    const chainId = urlParams.get('chainId');
    const chainName = urlParams.get('chainName');
    const orderId = urlParams.get('orderId');
    setValues({
      amount: amount,
      chainId: chainId,
      chainName: chainName,
      orderId: orderId,
    });

    console.log(amount, chainId, chainName);
  };

  //   const getOrderId = async () => {
  //     const url = `${EXAPAY_URL}/api/single-payment/create`;
  //     const response = await axios.post(url, {
  //       chainId: parseInt(values.chainId),
  //       token: EXAPAY_TOKEN,
  //       amount: values.amount,
  //       api_key: EXAPAY_API_KEY,
  //       receiver: {
  //         name: EXAPAY_RECEIVER_NAME,
  //         email: EXAPAY_RECEIVER_EMAIL,
  //       },
  //     });
  //     const orderId = response.data?.data?.id;
  //     return orderId;
  //   };

  const onButtonClick = async e => {
    console.log('button exapay');
    // const orderId = await getOrderId();
    // console.log(orderId);
    const returnUrl = encodeURIComponent(
      `${window.location.origin}/middle/order-result?amount=${values.amount}&type=createSinglePayment&orderId=${values.orderId}`
    );
    window.open(
      `${EXAPAY_URL}/order?id=${values.orderId}&name=Octopus&returnUrl=${returnUrl}`
    );
    setTimeout(() => {
      window.location.href = '/messages/rechargeRecord';
    }, 500);
  };

  const onReturnButtonClick = async values => {
    console.log('go back');
    window.history.back();
  };

  useEffect(() => {
    getUrlValues();
    console.log(userInfo);
  }, []);

  return (
    <>
      <Card>
        <Card
          hoverable={true}
          style={{
            backgroundColor: '#805AD5',
            color: 'white',
            height: '100%',
            width: '50%',
            margin: 'auto',
            marginTop: '50px',
          }}
        >
          <Box display="inline-block" height="20">
            <Box fontSize={'2xl'} fontWeight="bold" mb="3">
              Order confirmation
            </Box>
            <Box fontSize={'xl'}>Account Name: {userInfo.account}</Box>
          </Box>
          {values.chainId ? (
            <Box
              fontSize={'xl'}
              display="inline-block"
              float="right"
              height="20"
            >
              <Box textAlign="right" verticalAlign="middle" lineHeight="4.0">
                {values.chainName}
              </Box>
            </Box>
          ) : null}
          <Box
            backgroundColor="white"
            textColor="black"
            mt="5"
            p="5"
            fontSize="lg"
          >
            <Box>
              <Box display="inline-block" height="10" py="2">
                Exapay Topup
              </Box>
              <Box
                display="inline-block"
                height="10"
                float="right"
                textAlign="right"
                py="2"
              >
                $ {values.amount} USDT
              </Box>
            </Box>
            <Box>
              <Box display="inline-block" height="10" py="2">
                Amount Due
              </Box>
              <Box
                display="inline-block"
                height="10"
                float="right"
                textAlign="right"
                fontWeight="bold"
                py="2"
              >
                $ {values.amount} USDT
              </Box>
            </Box>
          </Box>
          <Box display="inline-block" height="20" mt="5">
            <Button size="lg" variant="bordered" onClick={onReturnButtonClick}>
              {'<'} Return to Octopus{' '}
            </Button>
          </Box>
          <Box
            display="inline-block"
            height="20"
            textAlign="right"
            alignItems="right"
            float="right"
            mt="5"
          >
            {' '}
            <Button size="lg" variant="bordered" onClick={onButtonClick}>
              {' '}
              Proceed to EXA pay for payment{' >'}
            </Button>
          </Box>
        </Card>
      </Card>
    </>
  );
};

export default OrderConfirmation;
