import { Button as ChakraButton, useToast, Spinner } from '@chakra-ui/react';
import {
  Space,
  Table,
  Form,
  Input,
  Modal,
  Cascader,
  Select,
  Upload,
  Button,
  message,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import { API_BASE_URL } from '../config';
import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../contexts/index.tsx';

function UploadPricing() {
  const token = localStorage.getItem('token');
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);

  const handleChange = info => {
    setFile(info.file);
    // console.log(fileList);
  };

  const handleSubmit = async file => {
    console.log(file);
    if (!file) {
      alert('Please select a file to upload!');
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append('file', file.originFileObj);
    try {
      const { data } = await axios.post(
        `${API_BASE_URL}/octopus/api/admin/pricing/upload`,
        formData,
        {
          headers: { Authorization: `Bearer ${token}` },
          'Content-Type': 'multipart/form-data', // Set content type for form data
        }
      );
      console.log('upload: ', data);
      setUploadSuccess(true);
      setLoading(false);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const props = {
    // action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
    onChange: handleChange,
    multiple: true,
  };
  return (
    <>
      <Upload {...props} file={file} accept=".xlsx, .xls">
        <Button icon={<UploadOutlined />}>Upload</Button>
      </Upload>
      <Button onClick={() => handleSubmit(file)} disabled={loading}>
        {loading ? <Spinner size="sm" /> : 'Submit'}
      </Button>
      {uploadSuccess && <p>File uploaded successfully!</p>}
    </>
  );
}

export default UploadPricing;
